// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {parseHTML} from '@github-ui/parse-html'
import visible from '@github-ui/visible'

//  A Gollum Editor.
//
//  Usage:
//  GollumEditor(); on DOM ready.

interface Options {
  MarkupType: string
  EditorMode: string
  NewFile: boolean
  HasFunctionBar: boolean
}

interface IncomingOptions {
  MarkupType?: string
  EditorMode?: string
  NewFile?: boolean
  HasFunctionBar?: boolean
}

// Editor options
const DefaultOptions: Options = {
  MarkupType: 'markdown',
  EditorMode: 'code',
  NewFile: false,
  HasFunctionBar: true,
}
let ActiveOptions: Options = DefaultOptions

// You don't need to do anything. Just run this on DOM ready.
export function GollumEditor(IncomingOptions: IncomingOptions) {
  ActiveOptions = Object.assign(DefaultOptions, IncomingOptions)

  if (EditorHas.baseEditorMarkup()) {
    // Initialize the function bar by loading proper definitions
    if (EditorHas.functionBar()) {
      const htmlSetMarkupLang = document
        .querySelector<HTMLElement>('#gollum-editor-body')!
        .getAttribute('data-markup-lang')

      if (htmlSetMarkupLang) {
        ActiveOptions.MarkupType = htmlSetMarkupLang
      }

      if (EditorHas.formatSelector()) {
        FormatSelector.init(document.querySelector<HTMLSelectElement>('#gollum-editor-format-selector select')!)
      }

      // load language definition
      LanguageDefinition.setActiveLanguage(ActiveOptions.MarkupType)

      if (EditorHas.help()) {
        const editorHelp = document.getElementById('gollum-editor-help')
        if (editorHelp) {
          editorHelp.style.display = 'none'
          editorHelp.classList.remove('jaws')
        }
      }
    }
  }
}

interface LanguageObject {
  [key: string]: definitionObject
}

// Defines a set of language actions that Gollum can use.
// Used by the definitions in langs/ to register language definitions.
export function defineLanguage(languageName: string, languageObject: LanguageObject) {
  if (typeof languageObject === 'object') {
    LanguageDefinition.define(languageName, languageObject)
  }
}

interface ILanguageDefinition {
  _ACTIVE_LANG: string
  _LOADED_LANGS: string[]
  _LANG: Record<string, LanguageObject>
  define(name: string, obj: LanguageObject): void
}

// Language definition file handler
// Loads language definition files as necessary.
const LanguageDefinition: ILanguageDefinition = {
  _ACTIVE_LANG: '',
  _LOADED_LANGS: [],
  _LANG: {},

  // Defines a language
  define(name: string, definitionObject: LanguageObject) {
    LanguageDefinition._ACTIVE_LANG = name
    LanguageDefinition._LOADED_LANGS.push(name)
    if (typeof GollumEditor.WikiLanguage === 'object') {
      const definition = {}
      Object.assign(definition, GollumEditor.WikiLanguage, definitionObject)
      LanguageDefinition._LANG[name] = definition
    } else {
      LanguageDefinition._LANG[name] = definitionObject
    }
  },

  getActiveLanguage() {
    return LanguageDefinition._ACTIVE_LANG
  },

  setActiveLanguage(name) {
    if (LanguageDefinition.getHookFunctionFor('deactivate')) {
      const fn = LanguageDefinition.getHookFunctionFor('deactivate')
      if (fn) fn()
    }

    function loaded() {
      // Update features that rely on the language definition.
      FunctionBar.refresh()

      if (LanguageDefinition.isValid() && EditorHas.formatSelector()) {
        FormatSelector.updateSelected()
      }

      if (LanguageDefinition.getHookFunctionFor('activate')) {
        const fn = LanguageDefinition.getHookFunctionFor('activate')
        if (fn) fn()
      }
    }

    if (!LanguageDefinition.isLoadedFor(name)) {
      LanguageDefinition._ACTIVE_LANG = ''
      // Well, fake it and turn everything off for this one.
      LanguageDefinition.define(name, {})
      loaded()
    } else {
      LanguageDefinition._ACTIVE_LANG = name
      loaded()
    }
  },

  getHookFunctionFor(attr: string, lang?: string) {
    let specifiedLang = lang
    if (!specifiedLang) {
      specifiedLang = LanguageDefinition._ACTIVE_LANG
    }

    if (
      LanguageDefinition.isLoadedFor(specifiedLang) &&
      LanguageDefinition._LANG[specifiedLang][attr] &&
      typeof LanguageDefinition._LANG[specifiedLang][attr] === 'function'
    ) {
      return LanguageDefinition._LANG[specifiedLang][attr]
    }

    return null
  },

  // gets a definition object for a specified attribute
  getDefinitionFor(attr: string, lang?: string) {
    let specifiedLang = lang
    if (!specifiedLang) {
      specifiedLang = LanguageDefinition._ACTIVE_LANG
    }

    if (
      LanguageDefinition.isLoadedFor(specifiedLang) &&
      LanguageDefinition._LANG[specifiedLang][attr] &&
      typeof LanguageDefinition._LANG[specifiedLang][attr] === 'object'
    ) {
      return LanguageDefinition._LANG[specifiedLang][attr]
    }

    return null
  },

  // Checks to see if a definition file has been loaded for the
  // specified markup language.
  isLoadedFor(markupName: string): boolean {
    if (LanguageDefinition._LOADED_LANGS.length === 0) {
      return false
    }

    for (let i = 0; i < LanguageDefinition._LOADED_LANGS.length; i++) {
      if (LanguageDefinition._LOADED_LANGS[i] === markupName) {
        return true
      }
    }
    return false
  },

  isValid() {
    return (
      LanguageDefinition._ACTIVE_LANG && typeof LanguageDefinition._LANG[LanguageDefinition._ACTIVE_LANG] === 'object'
    )
  },
}

// EditorHas
// Various conditionals to check what features of the Gollum Editor are
// active/operational.
const EditorHas = {
  // True if the basic editor form is in place.
  baseEditorMarkup(): boolean {
    return document.querySelector('#gollum-editor') != null && document.querySelector('#gollum-editor-body') != null
  },

  // True if the editor has a format selector (for switching between
  // language types), false otherwise.
  formatSelector(): boolean {
    return document.querySelector('#gollum-editor-format-selector select') != null
  },

  // True if the Function Bar markup exists.
  functionBar(): boolean {
    return ActiveOptions.HasFunctionBar && document.querySelector('#gollum-editor-function-bar') != null
  },

  // True if in a Firefox 4.0 Beta environment.
  ff4Environment(): boolean {
    const ua = new RegExp(/Firefox\/4.0b/)
    return ua.test(navigator.userAgent)
  },

  // True if the editor has a summary field (Gollum's commit message),
  // false otherwise.
  editSummaryMarkup(): boolean {
    return document.querySelector('input#gollum-editor-message-field') != null
  },

  // True if the editor contains the inline help sector, false otherwise.
  help(): boolean {
    return (
      document.querySelector('#gollum-editor #gollum-editor-help') != null &&
      document.querySelector('#gollum-editor #function-help') != null
    )
  },
}

interface definitionObject {
  exec?: (string, string, HTMLTextAreaElement) => void
  search?: RegExp
  replace?: string
  append?: string
}

const FunctionBar = {
  isActive: false,

  // FunctionBar.activate
  // Activates the function bar, attaching all click events
  // and displaying the bar.
  activate() {
    const functionBar = document.querySelector<HTMLElement>('#gollum-editor-function-bar')!
    for (const el of functionBar.querySelectorAll('.function-button')) {
      if (LanguageDefinition.getDefinitionFor(el.id) != null) {
        el.addEventListener('click', FunctionBar.evtFunctionButtonClick)
        el.classList.remove('disabled')
      } else if (el.id !== 'function-help') {
        el.removeEventListener('click', FunctionBar.evtFunctionButtonClick)
        el.classList.add('disabled')
      }
    }

    // show bar as active
    functionBar.classList.add('active')
    FunctionBar.isActive = true
  },

  deactivate() {
    const functionBar = document.querySelector<HTMLElement>('#gollum-editor-function-bar')!
    for (const el of functionBar.querySelectorAll('.function-button')) {
      el.removeEventListener('click', FunctionBar.evtFunctionButtonClick)
    }
    functionBar.classList.remove('active')
    FunctionBar.isActive = false
  },

  //  Event handler for the function buttons. Traps the click and
  //  executes the proper language action.
  evtFunctionButtonClick(e: MouseEvent) {
    const {currentTarget} = e
    e.preventDefault()
    const def = LanguageDefinition.getDefinitionFor(currentTarget.id)
    if (typeof def === 'object' && def) {
      FunctionBar.executeAction(def)
    }
  },

  // Executes a language-specific defined action for a function button.
  executeAction(definitionObject: DefinitionObject) {
    const body = document.getElementById('gollum-editor-body') as HTMLTextAreaElement
    // get the selected text from the textarea
    const txt = body.value
    // hmm, I'm not sure this will work in a textarea
    const selText = FunctionBar.getFieldSelection(body)
    let repText = typeof selText === 'string' ? selText : ''
    let reselect = true
    let cursor = null

    // execute a replacement function if one exists
    if (typeof definitionObject.exec === 'function' && typeof selText === 'string') {
      const exec = definitionObject.exec
      exec.call(definitionObject, txt, selText, body)
      return
    }

    // execute a search/replace if they exist
    let searchExp = /([^\n]+)/gi
    if (definitionObject.search && typeof definitionObject.search === 'object') {
      searchExp = new RegExp(definitionObject.search)
    }
    // replace text
    if (definitionObject.replace && typeof definitionObject.replace === 'string') {
      const rt = definitionObject.replace
      repText = repText.replace(searchExp, rt)
      // remove backreferences
      repText = repText.replace(/\$[\d]/g, '')

      if (repText === '') {
        // find position of $1 - this is where we will place the cursor
        cursor = rt.indexOf('$1')

        // we have an empty string, so just remove backreferences
        repText = rt.replace(/\$[\d]/g, '')

        // if the position of $1 doesn't exist, stick the cursor in
        // the middle
        if (cursor === -1) {
          cursor = Math.floor(rt.length / 2)
        }
      }
    }

    // append if necessary
    if (definitionObject.append && typeof definitionObject.append === 'string') {
      if (typeof selText === 'string' && repText === selText) {
        reselect = false
      }
      repText += definitionObject.append
    }

    if (repText) {
      FunctionBar.replaceFieldSelection(body, repText, reselect, cursor)
    }
  },

  // Retrieves the selection range for the textarea.
  getFieldSelectionPosition(field: HTMLTextAreaElement): {start: number; end: number} {
    return {
      start: field.selectionStart,
      end: field.selectionEnd,
    }
  },

  // Returns the currently selected substring of the textarea.
  getFieldSelection(field: HTMLTextAreaElement): string {
    const selPos = FunctionBar.getFieldSelectionPosition(field)
    return field.value.substring(selPos.start, selPos.end)
  },

  isShown() {
    const functionBar = document.querySelector('#gollum-editor-function-bar')
    return functionBar != null && visible(functionBar)
  },

  refresh() {
    if (EditorHas.functionBar()) {
      if (LanguageDefinition.isValid()) {
        FunctionBar.activate()
        if (Help) {
          Help.setActiveHelp(LanguageDefinition.getActiveLanguage())
        }
      } else {
        if (FunctionBar.isShown()) {
          // deactivate the function bar; it's not gonna work now
          FunctionBar.deactivate()
        }
        if (Help.isShown()) {
          Help.hide()
        }
      }
    }
  },

  // Replaces the currently selected substring of the textarea with
  // a new string.
  replaceFieldSelection(
    field: HTMLTextAreaElement,
    replaceText: string,
    reselect?: boolean,
    cursorOffset?: number | null,
  ) {
    const selPos = FunctionBar.getFieldSelectionPosition(field)
    const fullStr = field.value
    let selectNew = true
    if (reselect === false) {
      selectNew = false
    }

    let scrollTop = null
    if (field.scrollTop) {
      scrollTop = field.scrollTop
    }

    field.value = fullStr.substring(0, selPos.start) + replaceText + fullStr.substring(selPos.end)

    if (selectNew) {
      if (typeof cursorOffset === 'number' && cursorOffset > 0) {
        field.setSelectionRange(selPos.start + cursorOffset, selPos.start + cursorOffset)
      } else {
        field.setSelectionRange(selPos.start, selPos.start + replaceText.length)
      }
    }

    // focus on field region where cursor last pointed to
    field.focus()

    if (scrollTop) {
      // this jumps sometimes in FF
      field.scrollTop = scrollTop
    }
  },
}

interface IFormatSelector {
  SELECTOR: HTMLSelectElement | null
  init: (el: HTMLSelectElement) => void
  updateSelected: () => void
  evtChangeFormat: () => void
}

const FormatSelector: IFormatSelector = {
  SELECTOR: null,

  // Event handler for when a format has been changed by the format
  // selector. Will automatically load a new language definition
  // via JS if necessary.
  evtChangeFormat() {
    const newMarkup = this.value
    LanguageDefinition.setActiveLanguage(newMarkup)
  },

  // Initializes the format selector.
  init(sel: HTMLSelectElement) {
    // unbind events if init is being called twice for some reason
    if (FormatSelector.SELECTOR != null) {
      FormatSelector.SELECTOR.removeEventListener('change', FormatSelector.evtChangeFormat)
    }

    FormatSelector.SELECTOR = sel

    // set format selector to the current language
    FormatSelector.updateSelected()
    const selector = FormatSelector.SELECTOR
    if (selector) selector.addEventListener('change', FormatSelector.evtChangeFormat)
  },

  updateSelected() {
    const currentLang = LanguageDefinition.getActiveLanguage()
    const selector = FormatSelector.SELECTOR
    if (selector) selector.value = currentLang
  },
}

type helpDefinitionObject = Array<{
  menuName: string
  content: Array<{
    menuName: string
    data: string
  }>
}>

interface IHelp {
  _ACTIVE_HELP: string
  _ACTIVE_HELP_LANG: string
  _LOADED_HELP_LANGS: string[]
  _HELP: Record<string, HelpDefinitionObject>
  define(name: string, obj: HelpDefinitionObject): void
  showHelpFor(index1: string, index2: string): void
  clickFirstHelpLink(): void
  generateSubMenu(subData: unknown, helpIndex: number): void
  evtParentMenuClick(e: MouseEvent): void
  show(): void
  hide(): void
  isShown(): boolean
  generateHelpMenuFor(name: string): void
  evtHelpButtonClick(e: MouseEvent): void
  isValidHelpFormat(obj: DefinitionObject): boolean
}

// Functions that manage the display and loading of inline help files.
const Help: IHelp = {
  _ACTIVE_HELP: '',
  _ACTIVE_HELP_LANG: '',
  _LOADED_HELP_LANGS: [],
  _HELP: {},

  // Defines a new help context and enables the help function if it
  // exists in the Gollum Function Bar.
  define(name: string, definitionObject: HelpDefinitionObject) {
    const functionHelp = document.querySelector('#function-help')

    if (Help.isValidHelpFormat(definitionObject)) {
      Help._ACTIVE_HELP_LANG = name
      Help._LOADED_HELP_LANGS.push(name)
      Help._HELP[name] = definitionObject

      if (functionHelp) {
        functionHelp.classList.remove('disabled')
        functionHelp.addEventListener('click', Help.evtHelpButtonClick)

        // generate help menus
        Help.generateHelpMenuFor(name)

        const editorHelp = document.querySelector('#gollum-editor-help')
        if (editorHelp && editorHelp.hasAttribute('data-autodisplay')) {
          Help.show()
        }
      }
    } else {
      if (functionHelp) {
        functionHelp.classList.add('disabled')
        functionHelp.removeEventListener('click', Help.evtHelpButtonClick)
      }
    }
  },

  clickFirstHelpLink() {
    const firstLink = document.querySelector('#gollum-editor-help-list .menu-item')
    if (firstLink) {
      firstLink.click()
    }
  },

  // Generates the markup for the main help menu given a context name.
  generateHelpMenuFor(name: string) {
    if (!Help._HELP[name]) {
      return false
    }
    const helpData = Help._HELP[name]

    // clear this out
    const helpParent = document.querySelector<HTMLElement>('#gollum-editor-help-parent')!
    helpParent.textContent = ''
    document.querySelector<HTMLElement>('#gollum-editor-help-list')!.textContent = ''
    document.querySelector<HTMLElement>('#gollum-editor-help-content')!.textContent = ''

    // go go inefficient algorithm
    for (let i = 0; i < helpData.length; i++) {
      if (typeof helpData[i] != 'object') {
        break
      }

      const newLi = parseHTML(
        document,
        // eslint-disable-next-line github/unescaped-html-literal
        `<a href="#" rel="${i}" class="menu-item border-bottom">${helpData[i].menuName}</a>`,
      )
      const link = newLi.querySelector<HTMLElement>('a')!
      if (i === 0) {
        // select on first run
        link.classList.add('selected')
      }
      link.addEventListener('click', Help.evtParentMenuClick)
      helpParent.append(newLi)
    }

    // generate parent submenu on first run
    Help.generateSubMenu(helpData[0], 0)
    Help.clickFirstHelpLink()
  },

  // Generates the markup for the inline help sub-menu given the data
  // object for the submenu and the array index to start at.
  //
  // @param object subData The data for the sub-menu.
  // @param integer index  The index clicked on (parent menu index).
  // @return void
  generateSubMenu(subData, index) {
    const helpList = document.querySelector<HTMLElement>('#gollum-editor-help-list')!
    helpList.textContent = ''
    document.querySelector<HTMLElement>('#gollum-editor-help-content')!.textContent = ''
    for (let i = 0; i < subData.content.length; i++) {
      if (typeof subData.content[i] != 'object') {
        break
      }

      const subLi = parseHTML(
        document,
        // eslint-disable-next-line github/unescaped-html-literal
        `<a href="#" rel="${index}:${i}" class="menu-item border-bottom">${subData.content[i].menuName}</a>`,
      )

      for (const link of subLi.querySelectorAll('a')) {
        link.addEventListener('click', Help.evtSubMenuClick)
      }

      helpList.append(subLi)
    }
  },

  hide() {
    const help = document.querySelector('#gollum-editor-help')
    if (help) help.style.display = 'none'
  },

  show() {
    const help = document.querySelector('#gollum-editor-help')
    if (help) help.style.display = ''
  },

  // Displays the actual help content given the two menu indexes, which are
  // rendered in the rel="" attributes of the help menus
  showHelpFor(index1: string, index2: string) {
    const html = Help._HELP[Help._ACTIVE_HELP_LANG][index1].content[index2].data
    document.querySelector<HTMLElement>('#gollum-editor-help-content')!.innerHTML = html
  },

  // Returns true if help is loaded for a specific markup language,
  // false otherwise.
  isLoadedFor(name: string): boolean {
    for (let i = 0; i < Help._LOADED_HELP_LANGS.length; i++) {
      if (name === Help._LOADED_HELP_LANGS[i]) {
        return true
      }
    }
    return false
  },

  isShown(): boolean {
    const help = document.querySelector('#gollum-editor-help')
    return help != null && visible(help)
  },

  // Does a quick check to make sure that the help definition isn't in a
  // completely messed-up format.
  isValidHelpFormat(helpArr: helpDefinitionObject): boolean {
    return !!(
      typeof helpArr === 'object' &&
      helpArr.length &&
      typeof helpArr[0].menuName === 'string' &&
      typeof helpArr[0].content === 'object' &&
      helpArr[0].content.length
    )
  },

  // Sets the active help definition to the one defined in the argument,
  // re-rendering the help menu to match the new definition.
  setActiveHelp(name: string) {
    const functionHelp = document.querySelector('#function-help')
    if (!Help.isLoadedFor(name)) {
      if (functionHelp) {
        functionHelp.classList.add('disabled')
        functionHelp.removeEventListener('click', Help.evtHelpButtonClick)
      }
      if (Help.isShown()) {
        Help.hide()
      }
    } else {
      Help._ACTIVE_HELP_LANG = name
      if (functionHelp) {
        functionHelp.classList.remove('disabled')
        functionHelp.addEventListener('click', Help.evtHelpButtonClick)
        Help.generateHelpMenuFor(name)
      }
    }
  },

  // Event handler for clicking the help button in the function bar.
  evtHelpButtonClick(e: MouseEvent) {
    const {currentTarget} = e
    e.preventDefault()
    if (Help.isShown()) {
      // remove this entire block, leaving only Help.hide(), when the :wiki_hide_help_load FF is promoted
      // turn off autodisplay if it's on
      const helpElement = document.querySelector<HTMLElement>('#gollum-editor-help')!
      if (helpElement.hasAttribute('data-autodisplay')) {
        const url = currentTarget.getAttribute('data-dismiss-help-url')
        const token = currentTarget.parentElement!.querySelector<HTMLInputElement>('.js-data-dismiss-help-url-csrf')

        if (url && token) {
          const params = new URLSearchParams()

          fetch(url, {
            method: 'delete',
            mode: 'same-origin',
            body: params,
            headers: {
              'Scoped-CSRF-Token': token.value,
              'X-Requested-With': 'XMLHttpRequest',
            },
          })
          helpElement.removeAttribute('data-autodisplay')
        }
      }
      Help.hide()
    } else {
      Help.show()
    }
  },

  // Event handler for clicking on an item in the parent menu. Automatically
  // renders the submenu for the parent menu as well as the first result for
  // the actual plain text.
  evtParentMenuClick(e: MouseEvent) {
    e.preventDefault()
    const {currentTarget} = e
    // short circuit if we've selected this already
    if (currentTarget.classList.contains('selected')) {
      return
    }

    // populate from help data for this
    const helpIndex = currentTarget.rel

    const subData = Help._HELP[Help._ACTIVE_HELP_LANG][helpIndex]

    for (const link of document.querySelectorAll('#gollum-editor-help-parent .menu-item')) {
      link.classList.remove('selected')
    }
    currentTarget.classList.add('selected')
    Help.generateSubMenu(subData, helpIndex)
    Help.clickFirstHelpLink()
  },

  // Event handler for clicking an item in a help submenu. Renders the
  // appropriate text for the submenu link.
  evtSubMenuClick(e: MouseEvent) {
    e.preventDefault()
    const {currentTarget} = e
    if (currentTarget.classList.contains('selected')) {
      return
    }

    // split index rel data
    const rawIndex = currentTarget.rel.split(':')

    for (const link of document.querySelectorAll('#gollum-editor-help-list .menu-item')) {
      link.classList.remove('selected')
    }
    currentTarget.classList.add('selected')
    Help.showHelpFor(rawIndex[0], rawIndex[1])
  },
}

// Publicly-accessible function to Help.define
export const defineHelp = Help.define

// Dialog exists as its own thing now
export function replaceSelection(repText: string) {
  const field = document.querySelector<HTMLTextAreaElement>('#gollum-editor-body')!
  FunctionBar.replaceFieldSelection(field, repText)
}
